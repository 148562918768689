import React from "react"
import * as styles from "./ShopPageHeader.module.scss"

import loadable from "@loadable/component"
import MouseDownIcon from '../../assets/svgs/mouse-down.svg'
const FadeUpAndIn = loadable(() => import("../Animated/FadeUpAndIn"))

function ShopPageHeader({ scrollToSection  }) {
  return (
    <div className={styles.container}>
      <div>
        <div className={styles.titleContainer}>
          <FadeUpAndIn delay={1.8}>
            <div className={styles.title}>Shop around.</div>
          </FadeUpAndIn>
        </div>
        <div className={styles.descriptionContainer}>
          <FadeUpAndIn delay={1.9}>
            <div className={styles.description}>
              Take a look at some of our favorite products from various clients.
            </div>
          </FadeUpAndIn>
        </div>
      </div>
      <MouseDownIcon onClick={scrollToSection} style={{ cursor: 'pointer' }} />
    </div>
  )
}

export default ShopPageHeader
