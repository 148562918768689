import React, { useRef } from "react"
import { graphql } from "gatsby"

import SEO from "../components/seo"
import FadeIn from "../components/Animated/FadeIn"
import ShopPageHeader from "../components/ShopPageHeader"
import ProductList from "../components/ProductList"

const ShopPage = ({ data }) => {
  const products = data.contentfulProductList.products
  const sectionTwo = useRef()

  function scrollToSectionTwo() {
    sectionTwo.current.scrollIntoView({ behavior: "smooth", block: "start" })
  }

  return (
    <FadeIn>
      <SEO
        title="Shop | Buy Our Brands"
        description="Put your money where our mouth is."
      />
      <ShopPageHeader scrollToSection={scrollToSectionTwo} />
      <div ref={sectionTwo}>
        <ProductList products={[...products]} />
      </div>
    </FadeIn>
  )
}

export const query = graphql`
  query ShopPageQuery {
    contentfulProductList(listName: { eq: "Shop" }) {
      products {
        id
        client {
          name
          id
        }
        images {
          images {
            title
            gatsbyImageData(quality: 70)
            file {
              details {
                size
                image {
                  height
                  width
                }
              }
            }
          }
        }
        price
        promotionCode
        shopButtonColor
        shopButtonText
        shopButtonUrl
        title
        description
      }
    }
  }
`

export default ShopPage
