// extracted by mini-css-extract-plugin
export var hideForMobile = "ProductList-module--hide-for-mobile--yWRQN";
export var hideForTablet = "ProductList-module--hide-for-tablet--LCdI3";
export var hideForLaptop = "ProductList-module--hide-for-laptop--wAUjM";
export var hideForDesktop = "ProductList-module--hide-for-desktop--7mxTY";
export var hideForExtraLarge = "ProductList-module--hide-for-extra-large--4beQi";
export var showForMobileOnly = "ProductList-module--show-for-mobile-only--xVfom";
export var showForTabletOnly = "ProductList-module--show-for-tablet-only--nFNw-";
export var showForLaptopOnly = "ProductList-module--show-for-laptop-only--xesDm";
export var showForDesktopOnly = "ProductList-module--show-for-desktop-only--ZBSCd";
export var showForExtraLargeOnly = "ProductList-module--show-for-extra-large-only--VGGTu";
export var fullPageContainer = "ProductList-module--full-page-container---B-lx";
export var container = "ProductList-module--container--tQPW+";