import React from "react"
import loadable from "@loadable/component"
import * as styles from "./ProductList.module.scss"
import { useAnimation } from "framer-motion"
const Product = loadable(() => import("../Product"))

function ProductList({ products }) {
  const productAnimation = useAnimation()
  const handleProductAnimation = () => {
    productAnimation.start({
      opacity: 1,
      transition: { duration: 0.2 },
    })
  }

  const handleProductAnimationExit = () => {
    productAnimation.start({
      opacity: 0,
      transition: { duration: 0.2 },
    })
  }

  return (
    <div className={styles.container}>
      {products.map((product, index) => (
        <Product
          key={`Product-${index}`}
          product={product}
          id={`${product.id}-${index}`}
          productAnimationEnter={handleProductAnimation}
          productAnimationExit={handleProductAnimationExit}
          productAnimation={productAnimation}
        />
      ))}
    </div>
  )
}

export default ProductList
