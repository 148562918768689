// extracted by mini-css-extract-plugin
export var hideForMobile = "ShopPageHeader-module--hide-for-mobile--i6CRe";
export var hideForTablet = "ShopPageHeader-module--hide-for-tablet--qSwpg";
export var hideForLaptop = "ShopPageHeader-module--hide-for-laptop--caO3t";
export var hideForDesktop = "ShopPageHeader-module--hide-for-desktop--DRANk";
export var hideForExtraLarge = "ShopPageHeader-module--hide-for-extra-large--grUmr";
export var showForMobileOnly = "ShopPageHeader-module--show-for-mobile-only--HdCGU";
export var showForTabletOnly = "ShopPageHeader-module--show-for-tablet-only--OCKdn";
export var showForLaptopOnly = "ShopPageHeader-module--show-for-laptop-only--EoGEO";
export var showForDesktopOnly = "ShopPageHeader-module--show-for-desktop-only--khRKe";
export var showForExtraLargeOnly = "ShopPageHeader-module--show-for-extra-large-only--h1nHP";
export var fullPageContainer = "ShopPageHeader-module--full-page-container--+gkun";
export var container = "ShopPageHeader-module--container--KkT2f";
export var titleContainer = "ShopPageHeader-module--titleContainer--EWxPx";
export var title = "ShopPageHeader-module--title--nm-EB";
export var descriptionContainer = "ShopPageHeader-module--descriptionContainer--sfRrr";
export var description = "ShopPageHeader-module--description--VmJKz";